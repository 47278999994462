export function parseFields(fields?: string[]): {
  isNameLink: boolean
  isPhoneLink: boolean
  isAddressLink: boolean
  isEmailLink: boolean
  isGenderLink: boolean
  isAgeLink: boolean
  isCustomLink: boolean
  isCustom2Link: boolean
} {
  const isNameLink = Boolean(fields?.includes('dname'))
  const isEmailLink = Boolean(fields?.includes('email'))
  const isPhoneLink = Boolean(fields?.includes('phone'))
  const isAddressLink = Boolean(fields?.includes('address'))
  const isGenderLink = Boolean(fields?.includes('gender'))
  const isAgeLink = Boolean(fields?.includes('age'))
  const isCustomLink = Boolean(fields?.includes('custom'))
  const isCustom2Link = Boolean(fields?.includes('custom2'))
  return {
    isNameLink,
    isAgeLink,
    isPhoneLink,
    isAddressLink,
    isCustomLink,
    isEmailLink,
    isGenderLink,
    isCustom2Link
  }
}

interface GoogleAddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

// parses Google maps address components
export const buildAddressObject = (components: GoogleAddressComponent[] | undefined): any => {
  const componentKeys = {
    streetNumber: ['street_number'],
    street: ['street_address', 'route'],
    postalCode: ['postal_code'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ]
  }

  const address = {
    streetNumber: '',
    postalCode: '',
    street: '',
    region: '',
    city: ''
  }
  if ((components || []).length > 0) {
    ;(components || []).forEach(component => {
      for (const cKey in componentKeys) {
        if (componentKeys[cKey as keyof typeof address].indexOf(component.types[0]) !== -1) {
          address[cKey as keyof typeof address] = component.long_name
        }
      }
    })
  }
  return {
    streetAddress1: address.streetNumber + ' ' + address.street,
    city: address.city,
    region: address.region,
    postalCode: address.postalCode
  }
}
