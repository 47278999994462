import { Box } from '../../atoms/Box/index'
import { ComponentProps } from '../../../types/organisms/modal.types'
import { Icon } from '../../atoms/Icon/index'
import { ReactPortal, useEffect, useRef, useState } from 'react'
import { Text } from '../../atoms/Text/index'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { zIndex } from 'styled-system'
import ReactDOM from 'react-dom'
import closeIcon from '../../../static/icons/close-dark.svg'
import styled from 'styled-components'

type PosType = {
  top: string | number
  bottom?: number
  left?: number
  right?: number
}
const SheetWrapper = styled(Box)<{ delay: number }>`
  z-index: ${({ theme }) => theme.zIndex.modal};
  transition: ${props => `${props.delay}ms all`};
  ${zIndex}
`
export const FullPageModal = ({
  children,
  open,
  onClose,
  delay = 250,
  title,
  zIndex,
  closeButtonId,
  closeButtonIcon = closeIcon,
  hideCloseButton = false,
  titleProps,
  usingPortal = true,
  ...rest
}: ComponentProps): ReactPortal | JSX.Element | null => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const [pos, setPos] = useState<PosType>({ top: '100%' })
  const [portalOpen, setPortalOpen] = useState<boolean>(open)
  const isMounted = useIsMounted()

  useEffect(() => {
    let timeoutHandler: ReturnType<typeof setTimeout>
    if (open) {
      setPortalOpen(true)
      timeoutHandler = setTimeout(() => {
        setPos({ top: 0, bottom: 0, left: 0, right: 0 })
      }, 100)
    }
    if (!open && portalOpen) {
      setPos({ top: '100%' })
      timeoutHandler = setTimeout(() => {
        setPortalOpen(false)
      }, delay - 50)
    }
    return function cleanup() {
      clearTimeout(timeoutHandler)
    }
  }, [open, delay, portalOpen])

  const Content = (
    <SheetWrapper
      delay={delay}
      position="fixed"
      width="100%"
      overflowY="scroll"
      ref={drawerRef}
      {...pos}
      zIndex={zIndex}
    >
      <Box position="relative" backgroundColor="primary.white" width="100%" {...rest}>
        {!hideCloseButton && (
          <Box position="absolute" zIndex={12} top={22} right={22}>
            <Icon
              button
              onClick={onClose}
              src={closeButtonIcon}
              id={closeButtonId || 'close-fullpage-modal'}
              alt="close"
            />
          </Box>
        )}
        {title && (
          <Box position="absolute" top={22} left={22}>
            <Text variant="body/small" fontWeight="bold" {...titleProps}>
              {title}
            </Text>
          </Box>
        )}
        {children}
      </Box>
    </SheetWrapper>
  )

  if (isMounted()) {
    if (open && !usingPortal) return Content
    if (portalOpen) return ReactDOM.createPortal(Content, document.body)
  }
  return null
}
