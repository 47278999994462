import { Box } from '../../atoms/Box/index'
import { ComponentProps } from '../../../types/organisms/modal.types'
import { Icon } from '../../atoms/Icon/index'
import { MouseEvent, ReactPortal, useEffect, useRef, useState } from 'react'
import { PositionProps, ZIndexProps, zIndex } from 'styled-system'
import { Text } from '../../atoms/Text/index'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import ReactDOM from 'react-dom'
import closeIcon from '../../../static/icons/close-dark.svg'
import styled from 'styled-components'

const StyledAside = styled.aside<{ delay: number; bg: string } & ZIndexProps>`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: ${({ bg }: { bg: string }) => bg};
  transition: ${({ delay }: { delay: number }) => `${delay}ms background-color`};
  z-index: ${({ theme }) => theme.zIndex.aside};
  ${zIndex}
`
type PosType = {
  top: PositionProps['top']
  bottom?: PositionProps['bottom']
}
const SheetWrapper = styled(Box)<{ delay: number }>`
  z-index: ${({ theme }) => theme.zIndex.modal};
  transition: ${props => `${props.delay}ms all`};
`
export const SheetModal = ({
  children,
  open,
  onClose,
  delay = 250,
  title,
  zIndex,
  top = '50%',
  bottom = '0px',
  disableAutoClose = false,
  closeButtonId,
  closeButtonIcon = closeIcon,
  hideCloseButton = false,
  titleProps,
  minHeight = '50%',
  usingPortal = true,
  ...rest
}: ComponentProps): ReactPortal | JSX.Element | null => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const isMounted = useIsMounted()

  const [pos, setPos] = useState<PosType>({ top: '100%' })
  const [bg, setBg] = useState<string>('none')
  const [portalOpen, setPortalOpen] = useState<boolean>(open)
  const onOutsideClick = (e: MouseEvent<HTMLElement>): void => {
    if (drawerRef.current?.contains(e?.target as Node) || disableAutoClose) return
    onClose()
  }

  useEffect(() => {
    let timeoutHandler: ReturnType<typeof setTimeout>
    if (open) {
      setPortalOpen(true)
      timeoutHandler = setTimeout(() => {
        setPos({ top, bottom })
        setBg('rgba(0, 0, 0, 0.8)')
      }, 100)
    }
    if (!open && portalOpen) {
      setPos({ top: '100%' })
      setBg('none')
      timeoutHandler = setTimeout(() => {
        setPortalOpen(false)
      }, delay - 50)
    }
    return function cleanup() {
      clearTimeout(timeoutHandler)
    }
  }, [open, portalOpen, delay, top, bottom])

  const MUI_DRAWER_ZINDEX = 1200

  const Content = (
    <StyledAside
      zIndex={zIndex ?? MUI_DRAWER_ZINDEX}
      delay={delay}
      bg={bg}
      onClick={onOutsideClick}
    >
      <SheetWrapper delay={delay} position="absolute" width="100%" ref={drawerRef} {...pos}>
        <Box
          position="relative"
          borderRadius="12px 12px 0px 0px"
          backgroundColor="primary.white"
          width="100%"
          {...rest}
        >
          {!hideCloseButton && (
            <Box position="absolute" zIndex={12} top={22} right={22}>
              <Icon
                button
                onClick={onClose}
                src={closeButtonIcon}
                id={closeButtonId || 'close-sheet-modal'}
                alt="close"
              />
            </Box>
          )}
          {title && (
            <Box position="absolute" top={22} left={22}>
              <Text variant="body/small" fontWeight="bold" {...titleProps}>
                {title}
              </Text>
            </Box>
          )}
          {children}
        </Box>
      </SheetWrapper>
    </StyledAside>
  )

  if (isMounted()) {
    if (open && !usingPortal) return Content
    if (portalOpen) return ReactDOM.createPortal(Content, document.body)
  }
  return null
}
