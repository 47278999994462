import { theme } from '../../../theme'
import styled from 'styled-components'

export type CheckBoxProps = { error: boolean } & {
  disabled?: boolean
  readOnly?: boolean
}

// TODO: add styling for error
export const CheckBoxBase = styled.label<CheckBoxProps>`
  display: block;
  position: relative;
  cursor: pointer;
  width: auto;

  input {
    position: absolute;
    opacity: 0;
  }

  .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    background-color: ${({ disabled, readOnly }) =>
      disabled || readOnly ? theme.colors.secondary.border : theme.colors.primary.white};
    border-style: solid;
    border-width: 2px;
    border-color: ${theme.colors.secondary.border};
    border-radius: 4px;
    width: 24px;
    height: 24px;
  }

  input:checked ~ .checkbox-custom {
    background-color: ${({ disabled, readOnly }) =>
      disabled || readOnly ? theme.colors.secondary.border : theme.colors.primary.flowBlue};
    transform: rotate(0deg) scale(1);
    border-color: transparent;
    border-radius: 4px;
  }

  .checkbox-custom::after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: rotate(0deg) scale(0);
    transition: all 0.3s ease-out;
  }

  input:checked ~ .checkbox-custom::after {
    opacity: 1;
    border-style: solid;
    border-color: ${({ disabled, readOnly }) =>
      disabled || readOnly ? theme.colors.primary.black : theme.colors.primary.white};
    border-width: 0 1px 1px 0;
    border-radius: 0;
    transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
    width: 6px;
    height: 12px;
    top: 9px;
    left: 3.6px;
  }
`
